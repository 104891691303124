import styled, { css } from 'styled-components';
import Hero from 'components/HomepageHero';

// eslint-disable-next-line import/prefer-default-export
export const HeroStyled = styled(Hero)(
    ({ theme: { screenSize } }) => css`
        margin-top: 0;

        /* 1280 */
        @media screen and (min-width: ${screenSize.largeDesktop}px) {
            /* this is to specifically target the white content box */
            /* applies the change across all variations of the SITTER_HOMEPAGE_IMAGE_AND_COPY test */
            & > div {
                max-width: 681px;
            }
        }
    `
);
