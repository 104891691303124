import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import * as pageActions from 'containers/Page/actions';
import { SORT_FUNCTIONS, getSortFunction } from 'api/helpers/search/constants';
import { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import { getExperimentalFeatureVariationSelector } from 'containers/ExperimentalFeature/selectors';

import { getLocale } from 'shared/selectors';
import apiActions from 'api/actions';
import { createListingsSearchQuery } from 'utils/searchListings';

import { PAGE_ID, SEARCH_TYPE_RESULTS } from './constants';

// Get total number of active sits
export function* loadListings() {
    const { countryISOCode } = yield select(getLocale);
    const searchFilters = {
        sort: [
            {
                function: getSortFunction(SORT_FUNCTIONS.POPULAR_NEW_SITS, {
                    locationBias: {
                        countryCode: countryISOCode,
                    },
                }),
            },
        ],
    };
    const searchQuery = createListingsSearchQuery({
        searchPastAssignments: false,
        filters: searchFilters,
        searchPastAndFutureAssignments: true,
        perPage: 10,
    });
    const query = searchQuery.getRequestData();

    yield put(
        apiActions.search.loadListings({
            forceReload: true,
            filters: {
                query: JSON.stringify(query),
            },
            data: {
                searchType: SEARCH_TYPE_RESULTS,
                rawQuery: query,
            },
        })
    );
}

// Handle page loading
export function* load({ type, pageId }) {
    // Insert non lazy loadable requests here
    const { variation } = yield select(getExperimentalFeatureVariationSelector, {
        experiment: experiments.COOL_SITS_ON_HOMEPAGE,
    });

    if (variation === VariationTypes.VARIATION1) {
        yield call(loadListings);
    }

    if (type === pageActions.preload.ACTION) {
        yield put(pageActions.preloaded.create(pageId));
    } else if (type === pageActions.load.ACTION) {
        yield put(pageActions.loaded.create(pageId));
    }
}

export default function* pageSaga() {
    yield all([
        takeEvery(
            (action) =>
                [pageActions.preload.ACTION, pageActions.load.ACTION].includes(action.type) &&
                action.pageId === PAGE_ID,
            load
        ),
    ]);
}
