import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        width: calc(10 / 12 * 100%);
        margin: 0 auto;
    }
`;
