/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { H2 } from 'typography';

const SectionHeadingStyled = styled(H2)`
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    text-align: center;
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.xlarge};
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

export { SectionHeadingStyled };
