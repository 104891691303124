//
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListingCard from 'components/ListingCard';
import SkeletonListingCard from 'components/ListingCard/SkeletonListingCard';
import {
    ListingsCarouselContainer,
    ListingsCarouselHeader,
    ListingsCarouselTitle,
    ListingsCarouselStyled,
} from './ListingsCarousel.style';

export const ListingsCarouselTestIds = {
    title: 'sit-search-carousel-title',
    carousel: 'sit-search-carousel-listings',
};

const ListingsCarousel = ({ listings, isLoading }) => {
    const { t } = useTranslation();
    const emptyListings = [...Array(10).keys()];

    return (
        <ListingsCarouselContainer>
            <ListingsCarouselHeader>
                <ListingsCarouselTitle data-testid={ListingsCarouselTestIds.title}>
                    {t('pages_home_exp_cool_sits_title')}
                </ListingsCarouselTitle>
            </ListingsCarouselHeader>
            <ListingsCarouselStyled
                perPage={{
                    mobile: 1.2,
                    mediumHandset: 1.2,
                    largeHandset: 1.5,
                    smallTablet: 2,
                    mediumTablet: 2.2,
                    largeTablet: 2.5,
                    smallDesktop: 3,
                    mediumDesktop: 3.2,
                    largeDesktop: 4,
                    wide: 4,
                }}
                analyticsDescription="Homepage Find A Sit"
                analyticsItem="Homepage Find a Sit Carousel"
                data-testid={ListingsCarouselTestIds.carousel}
            >
                {isLoading
                    ? emptyListings.map((id) => <SkeletonListingCard key={id} />)
                    : listings.map((listing) => (
                          <ListingCard key={listing.id} listing={listing} isOutsideSearchPage />
                      ))}
            </ListingsCarouselStyled>
        </ListingsCarouselContainer>
    );
};

export default ListingsCarousel;
