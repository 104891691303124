/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Link from 'components/Link';
import { routes } from 'utils/routes';
import { APP_WEB_VIEW_TYPES } from 'config/constants';

import { SVGComponent as AppStoreBadge } from 'src/shared/assets/app_store_badge.svg';
import { SVGComponent as PlayStoreBadge } from 'src/shared/assets/play_store_badge.svg';

import {
    WrapperStyled,
    CopyWrapperStyled,
    ButtonWrapperStyled,
    StoreBadge,
    HeadingStyled,
    HeaderImage,
    PhoneImage,
} from './AppBanner.style';

import headerImage from './assets/header.jpg';
import phoneImage from './assets/homepage_app_screen.jpg';

const AppBanner = ({ isViewingInAppWebView }) => {
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <WrapperStyled ref={ref}>
            <HeaderImage image={headerImage} inView={inView}>
                <Link to={routes.page.page({ slug: 'app' })}>
                    <PhoneImage src={phoneImage} alt="TrustedHousesitters App" inView={inView} />
                </Link>
            </HeaderImage>
            <CopyWrapperStyled>
                <HeadingStyled>{t('pages_home_appBanner_heading')}</HeadingStyled>
                <ButtonWrapperStyled>
                    {isViewingInAppWebView === APP_WEB_VIEW_TYPES.android ? null : (
                        <StoreBadge
                            href="https://itunes.apple.com/gb/app/trustedhousesitters/id1292606611"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <AppStoreBadge />
                        </StoreBadge>
                    )}
                    {isViewingInAppWebView === APP_WEB_VIEW_TYPES.iOS ? null : (
                        <StoreBadge
                            href="https://play.google.com/store/apps/details?id=com.trustedhousesitters.TrustedHousesitters"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <PlayStoreBadge />
                        </StoreBadge>
                    )}
                </ButtonWrapperStyled>
            </CopyWrapperStyled>
        </WrapperStyled>
    );
};

AppBanner.defaultProps = {};

export default AppBanner;
