import styled from 'styled-components';

import { H2 } from 'typography';

import ImageLazyLoad from 'components/ImageLazyLoad';

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.grey[200]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex-direction: row;
        align-items: center;
        border-radius: ${({ theme }) => theme.borderRadius.regular};
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

const HeaderImage = styled.div`
    position: relative;

    width: 100%;

    html.no-js & {
        background-image: ${({ image }) => `url(${image})`};
    }

    background-image: ${({ image, inView }) => (inView ? `url(${image})` : '')};
    background-size: cover;
    align-self: stretch;

    /* aspect ratio calculation */
    padding-top: calc((2 / 3) * 100%);
    margin-right: ${({ theme }) => theme.spacing[2]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        /* half our aspect ratio padding because the container is now 50% width */
        padding-top: calc(((2 / 3) * 100%) / 2);

        border-top-left-radius: ${({ theme }) => theme.borderRadius.regular};
        border-bottom-left-radius: ${({ theme }) => theme.borderRadius.regular};

        width: calc(7 / 12 * 100%);

        margin-right: ${({ theme }) => theme.spacing[4]};
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        width: 50%;
    }
`;

const PhoneImage = styled(ImageLazyLoad)`
    min-width: 160px;
    /* using !important to overlap a display rule on ImageLazyLoad.style */
    display: none !important;
    background-color: white;

    position: absolute;
    top: 10%;
    /*
    * Should be 1 column from the right of the image.
    * The image takes up 6 columns so we know that
    * 1 column (1 / 6 * 100) percent of the parent
    */
    right: calc(1 / 6 * 100%);
    width: 35%;

    border: calc(100vw / 125) solid white;
    /*
    * calculates border radius based on screen width so that the radius scales nicely.
    */
    border-radius: calc(100vw / 34);

    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        /* using !important to overlap a display rule on ImageLazyLoad.style */
        display: block !important;
    }
`;

const COPY_MAX_WIDTH = 400;
const CopyWrapperStyled = styled.div`
    padding: ${({ theme }) => theme.spacing[3]};
    padding-bottom: ${({ theme }) => theme.spacing[4]};

    /* 600 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        padding-left: ${({ theme }) => theme.spacing[5]};
        padding-right: ${({ theme }) => theme.spacing[5]};
        padding-top: ${({ theme }) => theme.spacing[5]};
        padding-bottom: ${({ theme }) => theme.spacing[5]};
    }

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: calc(5 / 11 * 100%);
        padding-top: ${({ theme }) => theme.spacing[5]};
        padding-bottom: ${({ theme }) => theme.spacing[5]};
    }

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const HeadingStyled = styled(H2)`
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    line-height: 1.33;
    max-width: ${COPY_MAX_WIDTH}px;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        font-size: ${({ theme }) => theme.fontSizes.xlarge};
        line-height: 1.5;
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

const ButtonWrapperStyled = styled.div`
    display: flex;
    width: 100%;
    max-width: ${COPY_MAX_WIDTH}px;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        flex-direction: column;
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        flex-direction: row;
    }
`;

const StoreBadge = styled.a`
    height: 40px;
    margin-right: ${({ theme }) => theme.spacing[2]};

    &:last-of-type {
        margin-right: 0;
    }

    & > svg {
        height: 40px;
        fill: black;
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-right: 0;

        margin-bottom: ${({ theme }) => theme.spacing[2]};

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        margin-right: ${({ theme }) => theme.spacing[4]};

        &:last-of-type {
            margin-right: 0;
        }

        & > svg {
            height: 48px;
            fill: black;
        }
    }
`;

export {
    WrapperStyled,
    CopyWrapperStyled,
    ButtonWrapperStyled,
    StoreBadge,
    HeadingStyled,
    HeaderImage,
    PhoneImage,
};
