import { track, Events } from 'utils/analytics';
import { routes } from 'utils/routes';

const wizardSearchParams = new URLSearchParams(
    `t=${JSON.stringify({ wizardTrigger: 'Homepage CTAs' })}`
);

export const wizardQueryString = `?${wizardSearchParams.toString()}`;

export const createFindASitterUrl = ({ isLoggedIn }) =>
    isLoggedIn
        ? routes.search.profiles()
        : `${routes.search.findASitterWizard()}${wizardQueryString}`;

export const createFindAHouseSitUrl = ({ isLoggedIn }) =>
    isLoggedIn
        ? routes.search.listings()
        : `${routes.search.findAHouseSitWizard()}${wizardQueryString}`;

export const trackCTAButtonClick = (text) => {
    track(
        Events.BUTTONCLICK.create({
            description: 'Homepage main CTAs',
            item: `${text} - Hero`,
        })
    );
};
