import React from 'react';
import { Helmet } from 'react-helmet';
import { H1 } from '@ths/design-system';
import TrustpilotMini from 'components/Trustpilot/TrustpilotMini';
import { useInView } from 'react-intersection-observer';
import { scrollViewport } from 'utils/dom';
import { getFullHostname } from 'utils/environment';

import {
    LinkWrapperStyled,
    ContentWrapperStyled,
    HeroLinkStyled,
    SubHeadingStyled,
    WrapperStyled,
    MoreButtonStyled,
    IconChevronRightStyled,
} from './HomepageHero.style';

export const HeroCTA = ({ to, children, onClick }) => (
    <HeroLinkStyled to={to} onClick={onClick}>
        {children}
        <IconChevronRightStyled size="medLarge" />
    </HeroLinkStyled>
);

const Hero = ({
    heading,
    subheading,
    scrollButtonText,
    showScrollButton = true,
    mobileImageSrc,
    desktopImageSrc,
    children,
    className,
    location,
}) => {
    const ref = React.createRef();
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const scrollToHandler = () => scrollViewport(ref.current);

    return (
        <>
            <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${getFullHostname()}${location.pathname}`} />
                <meta property="og:title" content={heading} />
                <meta property="og:description" content={subheading} />
                <meta property="og:image" content={mobileImageSrc} />
            </Helmet>
            <WrapperStyled
                ref={inViewRef}
                inView={inView}
                className={className}
                heroMobile={mobileImageSrc}
                heroDesktop={desktopImageSrc}
            >
                {showScrollButton ? (
                    <MoreButtonStyled buttonRef={ref} scrollToHandler={scrollToHandler}>
                        {scrollButtonText}
                    </MoreButtonStyled>
                ) : null}
                <ContentWrapperStyled>
                    <H1 className="m-0 lg:text-[4.5rem] lg:leading-[5.75rem]">{heading}</H1>
                    <SubHeadingStyled>{subheading}</SubHeadingStyled>
                    <LinkWrapperStyled>{children}</LinkWrapperStyled>
                    <TrustpilotMini />
                </ContentWrapperStyled>
            </WrapperStyled>
        </>
    );
};

export default Hero;
