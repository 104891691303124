import React from 'react';
import { CountryCodes } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { Button, H1, P } from '@ths/design-system';
import { clsx } from 'clsx';
import { useInView } from 'react-intersection-observer';
import VideoLightbox from 'components/VideoLightbox';
import { IconPlay } from 'components/Icon';
import { IsoCode } from 'containers/ShowInCountry';
import image from './assets/homepage_video.jpg';

const bgImage = { backgroundImage: `url(${image})` };

const shadowWrapper =
    'after:content-[""] after:absolute after:opacity-30 md:after:rounded-l-lg after:w-full after:h-full after:bg-utility-black';

const imageStyles = clsx(
    'absolute z-0 h-full w-full bg-cover bg-no-repeat',
    'md:relative md:h-auto md:w-1/2 md:rounded-l-lg',
    shadowWrapper
);

const Video = () => {
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const UrlList = {
        GB: 'https://www.youtube.com/embed/IeqAKn-A0Rk',
        US: 'https://www.youtube.com/embed/xrJkHmR7RD8',
    };

    return (
        <div className="relative flex justify-stretch bg-primary-300 md:rounded-lg" ref={ref}>
            <div className={imageStyles} style={inView ? bgImage : {}} />
            <div className="flex w-full bg-cover px-8 py-16 md:w-1/2">
                <div className="relative z-10 md:max-w-[500px]">
                    <H1 className="font-semibold text-utility-white md:text-primary-700">
                        {t('pages_home_video_heading')}
                    </H1>
                    <P className="text-utility-white md:text-primary-700" size="lg">
                        {t('pages_home_video_body')}
                    </P>
                    <IsoCode>
                        {(isoCode) => {
                            let chooseURL = UrlList.GB;
                            if (isoCode === CountryCodes.US) {
                                chooseURL = UrlList.US;
                            }
                            return (
                                <VideoLightbox video={chooseURL}>
                                    {({ openLightbox }) => (
                                        <Button variant="primary" onClick={openLightbox}>
                                            {t('pages_home_video_button')}
                                            <IconPlay
                                                className="ml-auto sm:ml-2"
                                                size="medLarge"
                                                palette="highlight"
                                            />
                                        </Button>
                                    )}
                                </VideoLightbox>
                            );
                        }}
                    </IsoCode>
                </div>
            </div>
        </div>
    );
};

export default Video;
