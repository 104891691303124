import styled, { css } from 'styled-components';
import Link from 'components/Link';
import { H3 } from 'typography';
import { IconChevronRight } from 'components/Icon';
import Carousel from 'components/Carousel';

export const ListingsCarouselContainer = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-top: ${spacing[3]};

        /* 720 */
        @media only screen and (min-width: ${screenSize.mediumTablet}px) {
            margin-top: ${spacing[4]};
        }
        /* 960 */
        @media only screen and (min-width: ${screenSize.smallDesktop}px) {
            margin-top: ${spacing[5]};
        }
    `
);

export const ListingsCarouselHeader = styled.div(
    ({ theme: { spacing, screenSize } }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* add left and right margin on smaller screens so that carousel can extend to the border while other components still have spacing */
        margin: 0 ${spacing[3]};

        /* 720 */
        @media only screen and (min-width: ${screenSize.mediumTablet}px) {
            /* then remove margin since the SitDiscoveryContainer adds spacing for all of the components */
            /* since at this breakpoint the carousel goes from touch scrolling to using buttons */
            margin: 0;
        }
    `
);

export const ListingsCarouselTitle = styled(H3)(
    ({ theme: { screenSize, typography } }) => css`
        margin: 0;

        /* 720 */
        @media only screen and (min-width: ${screenSize.mediumTablet}px) {
            ${typography.h2Large};
        }
    `
);

export const ListingsCarouselStyled = styled(Carousel)(
    ({ theme: { spacing, screenSize } }) => css`
        margin: 0;
        margin-top: ${spacing[3]};

        /* 720 */
        @media only screen and (min-width: ${screenSize.mediumTablet}px) {
            margin-top: ${spacing[4]};
        }
        /* 960 */
        @media only screen and (min-width: ${screenSize.smallDesktop}px) {
            margin-top: ${spacing[6]};
        }
    `
);
