import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { experiments, VariationTypes } from 'containers/ExperimentalFeature';
import { useExperimentalFeature } from 'hooks/useExperimentalFeature';
import { HeroCTA } from 'components/HomepageHero';
import { routes } from 'utils/routes';
import beachDogMobileImg from './assets/beach-woman-dog-mobile-min.jpg';
import beachDogDesktopImg from './assets/beach-woman-dog-min.jpg';
import { HeroStyled } from './HeroLocalised.style';
import { createFindASitterUrl, createFindAHouseSitUrl, trackCTAButtonClick } from './utils';

export const HeroLocalisedTestIds = {
    hero: 'hero-localised-hero',
    ctaProfiles: 'hero-localised-cta-profiles',
    ctaListings: 'hero-localised-cta-listings',
    ctaOwnerJoin: 'hero-localised-cta-owner-join',
};

const Hero = ({ isLoggedIn }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [homePageCTAFeature] = useExperimentalFeature(experiments.HOMEPAGE_CTA);
    const isHomePageCTAInControl = homePageCTAFeature === VariationTypes.CONTROL;
    const searchProfilesTo = (() => {
        if (isLoggedIn) {
            return routes.search.profiles();
        }

        return isHomePageCTAInControl
            ? createFindASitterUrl({ isLoggedIn })
            : routes.join.petParents();
    })();

    return (
        <HeroStyled
            t={t}
            heading={t('pages_home_OwnerFocus_heroLocalised_heading')}
            subheading={t('pages_home_OwnerFocus_heroLocalised_subheading')}
            mobileImageSrc={beachDogMobileImg}
            desktopImageSrc={beachDogDesktopImg}
            scrollButtonText={t('pages_home_hero_scrollToButton_button')}
            location={location}
            data-testid={HeroLocalisedTestIds.hero}
        >
            <HeroCTA
                to={searchProfilesTo}
                data-testid={HeroLocalisedTestIds.ctaProfiles}
                onClick={() =>
                    trackCTAButtonClick(
                        t(
                            isHomePageCTAInControl || isLoggedIn
                                ? 'pages_home_hero_searchSitters'
                                : 'pages_home_hero_homepage_cta_test_search_sitters'
                        )
                    )
                }
            >
                {t(
                    isHomePageCTAInControl || isLoggedIn
                        ? 'pages_home_hero_searchSitters'
                        : 'pages_home_hero_homepage_cta_test_search_sitters'
                )}
            </HeroCTA>
            <HeroCTA
                to={createFindAHouseSitUrl({ isLoggedIn })}
                data-testid={HeroLocalisedTestIds.ctaListings}
                onClick={() => trackCTAButtonClick(t('pages_home_hero_searchSits'))}
            >
                {t('pages_home_hero_searchSits')}
            </HeroCTA>
        </HeroStyled>
    );
};

export default Hero;
