import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import TrustpilotList from 'components/Trustpilot/TrustpilotList';
import MembershipServicesModule from 'components/MembershipServicesModule';
import Feature, { features } from 'components/Feature';
import ExperimentalFeature, {
    experiments,
    userTypes,
    UTM_SOURCES,
} from 'containers/ExperimentalFeature';

import ListingsCarousel from './components/ListingsCarousel';
// Homepage sections
import { Section, SectionHowItWorks } from './Home.style';
import HowItWorks from './components/HowItWorks';
import WhatsNext from './components/WhatsNext';
import Video from './components/Video';
import AppBanner from './components/AppBanner';
import InlineCTA from './components/InlineCTA';
import HeroLocalised from './components/HeroLocalised';
import WantPetSitter from './components/WantPetSitter';
import THSLogoJsonLdImage from './assets/ths-logo-json-ld.jpg';

const Home = ({ seoContent, isViewingInAppWebView, listings, hasCarouselListingsLoaded }) => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                {!seoContent.hasTitle && <title>{t('pages_home_title')}</title>}
                {!seoContent.hasDescription && (
                    <meta name="description" content={t('pages_home_description')} />
                )}
                <script type="application/ld+json">
                    {`{"@context": "http://schema.org","@type": "Corporation","name": "TrustedHousesitters","description": "TrustedHousesitters is a global, online platform connecting pet owners with a community of verified and reviewed pet sitters. Founded in 2010, they've connected thousands of members in over 130+ countries worldwide. Joining for one annual fee, TrustedHousesitters' members help each other by exchanging in-home pet care for unique homestays around the world. It's a money-free exchange that helps pets stay happy at home while their owners are away, while sitters get to unlock unique travel experiences and enjoy the company of pets.","image": "https://cdn.trustedhousesitters.com/static/cc675d06f5a847c2e4a6.jpg","logo": "${THSLogoJsonLdImage}","url": "https://www.trustedhousesitters.com/","telephone": "08081785384","sameAs": ["https://twitter.com/Housesitting ","https://www.linkedin.com/company/trustedhousesitters-com","https://facebook.com/trustedhousesitters ","https://www.youtube.com/user/trustedhousesitters ","https://instagram.com/trustedhousesitters","https://www.crunchbase.com/organization/trusted-housesitters ","https://www.pinterest.co.uk/housesitting/ "],"address": {"@type": "PostalAddress","streetAddress": "20-22 Wenlock Road ","addressLocality": "London","postalCode": "N1 7GU","addressCountry": "United Kingdom"}}`}
                </script>
            </Helmet>

            <div className="lg:p-12">
                <HeroLocalised />
            </div>

            <ExperimentalFeature
                experiment={experiments.HOMEPAGE_CTA}
                control={<></>}
                variation1={<></>}
            />

            {/* An empty experimentalfeature for analytics events
                This will cover the GetSiteControl banner entry for anon users */}
            <ExperimentalFeature
                experiment={experiments.FREE_TRIAL_OWNER_V2}
                excludeUTMSources={[UTM_SOURCES.CJ, UTM_SOURCES.IMPACT]}
                excludeCombo={[userTypes.PaidUser, userTypes.ExpiredUser, userTypes.PartialUser]}
                control={<></>}
                variation1={<></>}
            />

            <ExperimentalFeature
                experiment={experiments.FREE_TRIAL_SITTER_V1}
                excludeUTMSources={[UTM_SOURCES.CJ, UTM_SOURCES.IMPACT]}
                excludeCombo={[
                    userTypes.PaidUser,
                    userTypes.ExpiredUser,
                    userTypes.PartialOwner,
                    userTypes.PartialCombined,
                ]}
                control={<></>}
                variation1={<></>}
            />

            <ExperimentalFeature
                experiment={experiments.COOL_SITS_ON_HOMEPAGE}
                control={
                    <SectionHowItWorks top bottom>
                        <HowItWorks />
                    </SectionHowItWorks>
                }
                variation1={
                    <Section bottom fullWidth>
                        <ListingsCarousel
                            listings={listings}
                            isLoading={!hasCarouselListingsLoaded}
                        />
                    </Section>
                }
            />

            <InlineCTA t={t} trackingKey={1} />
            <>
                <Section top bottom>
                    <WantPetSitter />
                </Section>

                <Section top bottom fullWidth>
                    <Video />
                </Section>

                <Section top bottom>
                    <MembershipServicesModule
                        t={t}
                        heading={t('pages_home_membershipServices_heading')}
                        quote={t('pages_home_membershipServices_quote')}
                        name={t('pages_home_membershipServices_name')}
                    />
                </Section>

                <InlineCTA t={t} trackingKey={2} />

                <Feature
                    name={features.TRUSTPILOT_REVIEWS}
                    on={
                        <Section top bottom>
                            <TrustpilotList />
                        </Section>
                    }
                    off={null}
                />

                <Section top bottom>
                    <WhatsNext t={t} />
                </Section>
                <Section top bottom fullWidth>
                    <AppBanner translate={t} isViewingInAppWebView={isViewingInAppWebView} />
                </Section>

                <InlineCTA t={t} trackingKey={3} />
            </>
        </>
    );
};

export default Home;
