import React from 'react';
import Anchor from 'components/Anchor';
import { FlexboxGrid, Col } from 'components/layouts';
import {
    MSContainerStyled,
    TextContainerStyled,
    HeadingStyled,
    QuoteContainerStyled,
    QuoteStyled,
    ParagraphStyled,
    NameParagraphStyled,
    ParagraphLinkStyled,
    ButtonStyled,
    LinkStyled,
} from './MembershipServicesModule.style';

const isLivechatEnabled = () => {
    if (global.window && global.window.zE) {
        return true;
    }
    return false;
};

const openLivechat = () => {
    if (isLivechatEnabled()) {
        global.window.zE.activate();
    }
};

const MembershipServicesModule = ({ t, heading, quote, name }) => {
    const Livechat = () => (
        <ButtonStyled isUnderlined onClick={openLivechat}>
            {t('components_membershipServices_livechat_body')}
        </ButtonStyled>
    );

    const HelpDesk = () => (
        <LinkStyled isUnderlined href="https://support.trustedhousesitters.com/hc/en-gb">
            {t('components_membershipServices_helpdesk_body')}
        </LinkStyled>
    );

    return (
        <FlexboxGrid justify={FlexboxGrid.Justify.CENTER}>
            <Col smallTablet={12} mediumTablet={12} mediumDesktop={12}>
                <MSContainerStyled>
                    <TextContainerStyled>
                        <HeadingStyled>{heading}</HeadingStyled>
                        <QuoteContainerStyled>
                            <QuoteStyled>“</QuoteStyled>
                            <ParagraphStyled>{quote}</ParagraphStyled>
                            <NameParagraphStyled>
                                {name}
                                <Anchor
                                    isUnderlined
                                    href="https://uk.trustpilot.com/review/www.trustedhousesitters.com"
                                >
                                    {t('pages_searchProfiles_membershipServices_trustpilot')}
                                </Anchor>
                            </NameParagraphStyled>
                        </QuoteContainerStyled>
                        <ParagraphLinkStyled>
                            {t('components_membershipServices_contactUs_body')}
                            {isLivechatEnabled() === true ? (
                                <>
                                    <HelpDesk />
                                    <span>{t('components_membershipServices_or')}</span>
                                    <Livechat />
                                </>
                            ) : (
                                <HelpDesk />
                            )}
                            <span>.</span>
                        </ParagraphLinkStyled>
                    </TextContainerStyled>
                </MSContainerStyled>
            </Col>
        </FlexboxGrid>
    );
};

export default MembershipServicesModule;
