/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { getSeoContent, isViewingInAppWebView } from 'shared/selectors';
import ExperimentalFeature, { experiments } from 'containers/ExperimentalFeature';
import { asPageWithSagas } from 'containers/Page';

import { settings } from 'api/actions';
import { hasRequestEnded } from 'api/selectors/request';
// vv Used for MX_SITTER_HOMEPAGE test
import { getIsMembershipActive, isSitterOnly } from 'utils/account';
import { getAccountCurrentMembershipPlan } from 'api/selectors/account';
import { getSearchListings } from 'api/selectors/search';
import { Redirect } from 'react-router';
import { routes } from 'utils/routes';
// ^^ Used for MX_SITTER_HOMEPAGE test

import { PAGE_ID, PAGE_SAGA_KEY, SEARCH_TYPE_RESULTS } from './constants';
import Home from './Home';
import homePageSaga from './sagas';

const pageSaga = {
    key: PAGE_SAGA_KEY,
    saga: homePageSaga,
};

const mapStateToProps = (state) => {
    const membership = getAccountCurrentMembershipPlan(state);
    const isPaidMember = getIsMembershipActive(membership);
    const isSitter = isSitterOnly(membership);
    const isPaidSitter = isPaidMember && isSitter;
    const hasCarouselListingsLoaded = hasRequestEnded({
        state,
        actionSettings: settings.search.loadListings,
    });

    return {
        listings: getSearchListings(state, SEARCH_TYPE_RESULTS),
        seoContent: getSeoContent(state),
        isViewingInAppWebView: isViewingInAppWebView(state),
        isPaidSitter,
        hasCarouselListingsLoaded,
    };
};

const ConnectedHomeWithTest = connect(mapStateToProps)((props) => {
    // We only want to include paid sitters in the experiment
    if (props.isPaidSitter) {
        return (
            <ExperimentalFeature
                experiment={experiments.SITTER_HOMEPAGE}
                control={<Home {...props} />}
                variation1={<Redirect to={routes.search.listings()} />}
                analytics={false}
            />
        );
    }
    return <Home {...props} />;
});

const HomePage = asPageWithSagas(ConnectedHomeWithTest, PAGE_ID, pageSaga);

export { HomePage as default };
