import React, { Fragment } from 'react';
import { routes } from 'utils/routes';
import LinkCard from 'components/LinkCard';
import LinkCardGrid from 'components/LinkCardGrid';
import { useTranslation } from 'react-i18next';
import { track, Events } from 'utils/analytics';

import { SectionHeadingStyled } from './WhatsNext.style';

const handleButtonClick = (buttonText) => {
    track(
        Events.BUTTONCLICK.create({
            description: 'Homepage main CTAs',
            item: `${buttonText} - Information section`,
        })
    );
};

const WhatsNext = () => {
    const { t } = useTranslation();

    return (
        <>
            <SectionHeadingStyled>{t('pages_home_whatsNext_heading')}</SectionHeadingStyled>
            <LinkCardGrid>
                <LinkCard
                    informationHeading={t('pages_home_whatsNext_card_1_heading')}
                    textBody={t('pages_home_whatsNext_card_1_body')}
                    linkText={t('pages_home_whatsNext_card_1_link')}
                    linkURL={routes.search.profiles()}
                    onClick={() => handleButtonClick(t('pages_home_whatsNext_card_1_link'))}
                />
                <LinkCard
                    informationHeading={t('pages_home_whatsNext_card_2_heading')}
                    textBody={t('pages_home_whatsNext_card_2_body')}
                    linkText={t('pages_home_whatsNext_card_2_link')}
                    linkURL={routes.search.listings()}
                    onClick={() => handleButtonClick(t('pages_home_whatsNext_card_2_link'))}
                />
            </LinkCardGrid>
        </>
    );
};

export default WhatsNext;
