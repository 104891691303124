import styled from 'styled-components';
import { P } from 'typography';
import ScrollToButton from 'components/ScrollToButton';
import Link from 'components/Link';
import { IconChevronRight } from 'components/Icon';

const WrapperStyled = styled.div`
    html.no-js & {
        background-image: ${({ heroMobile }) => `url(${heroMobile})`};

        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            background-image: ${({ heroDesktop }) => `url(${heroDesktop})`};
        }
    }
    background-image: ${({ heroMobile, inView }) => (inView ? `url(${heroMobile})` : '')};
    background-size: cover;
    background-position: center;
    position: relative;
    height: 192px;
    margin-bottom: ${({ theme }) => theme.spacing[54]};

    /* 480 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeHandset}px) {
        height: 288px;
        margin-bottom: ${({ theme }) => theme.spacing[30]};
    }
    /* 600 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        margin-bottom: 220px;
    }
    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        background-image: ${({ heroDesktop, inView }) => (inView ? `url(${heroDesktop})` : '')};
        border-radius: ${({ theme }) => theme.borderRadius.regular};
        margin-top: ${({ theme }) => theme.spacing[3]};
        margin-bottom: 180px;
        height: 288px;
    }
    /* 960 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        height: auto;
        display: block;
        margin-bottom: ${({ theme }) => theme.spacing[0]};
        padding: ${({ theme }) => theme.spacing[8]};
    }

    /* 1024 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        min-height: 336px;
        margin-top: ${({ theme }) => theme.spacing[4]};
    }

    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        min-height: 448px;
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

const ContentWrapperStyled = styled.div`
    position: absolute;
    top: 168px;
    min-width: calc((10 / 12) * 100%);
    background: #ffffff;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${({ theme }) => theme.spacing[3]};
    padding-top: ${({ theme }) => theme.spacing[2]};
    border: 1px solid #e6e6e6;
    box-shadow: 0px 15px 25px #e6e6e6;
    left: 50%;
    transform: translate(-50%, 0%);

    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeHandset}px) {
        top: 216px;
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumHandset}px) {
        & iframe {
            height: 40px !important;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        & iframe {
            height: 20px !important;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        min-width: auto;
        top: 240px;
        padding: ${({ theme }) => theme.spacing[4]};
        padding-top: ${({ theme }) => theme.spacing[3]};
        max-width: 100%;
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        max-width: 520px;
        padding: ${({ theme }) => theme.spacing[4]};
        position: static;
        box-shadow: none;
        transform: none;
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        max-width: 740px;
        padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[6]};
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.extraWide}px) {
        max-width: 880px;
    }
`;

const SubHeadingStyled = styled(P)`
    margin-top: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    /* 600 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[3]};
    }
    /* 960 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        ${({ theme }) => theme.typography.focalSmall};
    }
`;

const LinkWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => `${theme.spacing[3]}`};
    max-width: 100%;

    /* 600 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        flex-direction: row;
    }

    /* 1440 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.wide}px) {
        max-width: 100%;
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

const HeroLinkStyled = styled(Link).attrs({
    variant: Link.Variant.Button.PRIMARY,
})`
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    &:last-of-type {
        margin-bottom: 0;
    }
    padding-right: ${({ theme }) => theme.spacing[1]};

    /* 600 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[0]};
        margin-right: ${({ theme }) => theme.spacing[3]};
        &:last-of-type {
            margin-right: ${({ theme }) => theme.spacing[0]};
        }
    }

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[0]};

        &:last-of-type {
            margin-right: ${({ theme }) => theme.spacing[0]};
        }
    }

    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        height: 4.5rem;
        font-size: 20px;
        margin-right: ${({ theme }) => theme.spacing[5]};
        padding: ${({ theme }) => theme.spacing[1.5]} ${({ theme }) => theme.spacing[2]};
    }
`;

const MoreButtonStyled = styled(ScrollToButton)`
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);

    /* 960 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: flex;
    }
`;

const IconChevronRightStyled = styled(IconChevronRight)`
    margin-left: auto;
    & svg {
        fill: ${({ theme }) => theme.palette.highlight};
    }

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        margin-left: ${({ theme }) => theme.spacing[1]};
    }
`;

export {
    WrapperStyled,
    ContentWrapperStyled,
    SubHeadingStyled,
    LinkWrapperStyled,
    HeroLinkStyled,
    MoreButtonStyled,
    IconChevronRightStyled,
};
