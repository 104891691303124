import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'components/Link';
import InformationSection from 'components/InformationSection';
import { track, Events } from 'utils/analytics';
import { routes } from 'utils/routes';
import { WrapperStyled } from './WantPetSitter.style';
import needAPetSitterImage from './assets/need-a-pet-sitter.jpg';
import needToGoPetSittingImage from './assets/want-to-go-pet-sitting.jpg';

const handleButtonClick = (buttonText) => {
    track(
        Events.BUTTONCLICK.create({
            description: 'Homepage main CTAs',
            item: `${buttonText} - Information section`,
        })
    );
};

const WantPetSitter = () => {
    const { t } = useTranslation();

    const sections = [
        {
            heading: `pages_home_wantPetSitter_needAPetSitter_heading`,
            body: `pages_home_wantPetSitter_needAPetSitter_body`,
            link: `pages_home_wantPetSitter_needAPetSitter_link`,
            url: routes.search.profiles(),
            image: {
                src: needAPetSitterImage,
                position: InformationSection.ImagePosition.LEFT,
            },
        },
        {
            heading: `pages_home_wantPetSitter_needToGoPetSitting_heading`,
            body: `pages_home_wantPetSitter_needToGoPetSitting_body`,
            link: `pages_home_wantPetSitter_needToGoPetSitting_link`,
            url: routes.search.listings(),
            image: {
                src: needToGoPetSittingImage,
                position: InformationSection.ImagePosition.RIGHT,
            },
        },
    ];

    return (
        <WrapperStyled>
            {sections.map(({ heading, body, link, url, image }) => (
                <InformationSection
                    key={heading}
                    heading={t(heading)}
                    textBody={t(body)}
                    linkText={t(link)}
                    url={url}
                    ctaOptions={{
                        variant: Link.Variant.Button.PRIMARY,
                        onClick: () => {
                            handleButtonClick(t(link));
                        },
                    }}
                    image={image.src}
                    imagePosition={image.position}
                    variant={InformationSection.Variant.LARGE}
                />
            ))}
        </WrapperStyled>
    );
};

export default WantPetSitter;
